import { useContext, useEffect, useState } from "react";
import StarRating from "./starRating";
import BlogFooter from "../../components/blog-footer/BlogFooter";
import { ShopContext } from "../../context/ShopContext";
import axios from "axios";
import './shopPage.scss'

export default function ShopPage() {
    const { 
        products, 
        addToCart, 
        searchTerm, 
        setSearchTerm, 
        handleCategoryChange,
        selectedCategory} = useContext(ShopContext);
    /*const [items, setItems] = useState('');

    const productTest = [
        {id:1, name: 'Hyper Real Fresh Canvas Cream-To-Foam Cleanser' , 
            description: "DSAAAAAA adsaaaaaaaaaaaaa asdddddddddddddddddddddddddddddddddddddddddddd asddddddddddddddddddddddd",
            rating: 3.6
        },
        {id:2, name: 'Hyper Real Fresh Canvas Cream-To-Foam Cleanser',
            rating: 4.5
        },
        {id:3, name: 'Hyper Real Fresh Canvas Cream-To-Foam Cleanser',
            rating: 2.6
        },
        {id:4, name: 'Hyper Real Fresh Canvas Cream-To-Foam Cleanser',
            rating: 5
        }
    ];


    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await axios.post('/api/ShopItems');
                setItems(response.data);
                

            } catch (err) {
                console.log('Error fetching post:', err);
            }
        }
        fetchPost();
    }, [])*/


    console.log(products);
    //console.log(items);


    return(
        <>
            <main className="Shop-Page-Container">
                <header className="Shop-Page-Title">
                    <p>Shop</p>
                </header>
                <section className="Shop-Page-Inner-Container">
                    <aside className="Shop-Page-Side-Bar">
                        <p>Side Bar</p>
                        <select
                            value={selectedCategory}
                            onChange={(e) => handleCategoryChange(e.target.value)}
                        >
                            <option value=''></option>
                            <option value=''></option>
                            <option value=''></option>
                            <option value=''></option>
                        </select>
                    </aside>
                    <section className="Shop-Page-Search">
                        <p>Search box</p>
                        <input
                            type="text"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </section>
                    <section className="Shop-Page-Products">
                        <ul className="Shop-Page-Items-List">
                            {Array.isArray(products) && products.length > 0 ?      
                            (products.map((product) => (
                                <li key={product.id} className="Shop-Page-Items">
                                    <div className="Shop-Page-Display">
                                        <div className="Shop-Page-Stars">
                                            <StarRating rating={product.rating} />
                                        </div>
                                        <div className="Shop-Page-Images">
                                            <img className="Shop-Page-Image-Style" fetchpriority="high" loading="eager" src={product.image} alt="/images/face-cream.jpg"></img>
                                        </div>
                                        <div className="Shop-Page-Items-Text">
                                            <p className="Shop-page-Items-Title">{product.name}</p>
                                            <p className="Shop-page-Items-Price">{product.price} $</p>
                                            <p className="Shop-page-Items-Description">{product.description}</p>
                                        </div>
                                        <div className="Shop-Page-Icons">
                                            <i className="Shop-Page-Heart-Empty fa-regular fa-heart"></i>
                                            <i className="Shop-Page-Add-Cart fa-solid fa-cart-plus"
                                            onClick={(e) => {
                                                addToCart(product);
                                            }}
                                            ></i>
                                        </div>
                                    </div>
                                </li>
                            ))) : (
                                <p>No Products to Display</p>
                            )
                        }
                        </ul>
                    </section>
                </section>
            </main>
            <BlogFooter />
        </>
    )
} 