import { useEffect, useState, useRef, useContext } from 'react';
import './shoppingCart.scss';
import { ShopContext } from '../../context/ShopContext';

export default function ShoppingCart( {ShopingStyle} ) {
    //const [isCartVisible, setCartVisible] = useState(false);
    const [isEmpty, setEmpty] = useState(true); //used to determine if a message should be show empty cart
    const {
        removeItemCart,
        addToCart, 
        clearCart, 
        cart, 
        cartRef, 
        isCartVisible, 
        toggleVisible, 
        calculateTotal, 
        calculateItemTotal,
        removeAllSpecificItem,
        calculateAmountOfItem
    } = useContext(ShopContext);


    return(
        <div className="Shopping-Cart-Container" ref={cartRef}>
            <i className={`${ShopingStyle} fa-solid fa-basket-shopping`} onClick={toggleVisible}></i>
            {isCartVisible && 
            <div className="Shopping-Cart-Top-Bar">
                <div className='Shopping-Cart-Title'>
                    <p className='Shopping-Cart-TitleStyle'>Shopping Cart</p>
                    <button className='Shopping-Cart-ClearButton' onClick={clearCart}> Remove all</button>
                </div> 
                <section className='Shop-Cart-Items-Section'>
                    <ul className='Shop-Cart-Items-List'>
                        {
                            cart.map((product) => (
                                <li key={product.id} className='Shop-Cart-Items-Inner'>
                                    <div className='Shop-Cart-Product'>
                                        <div className='Shop-Cart-Image'>
                                            <img className='Shop-Cart-Image-Inner' src={product.image}></img>
                                        </div>
                                        <div className='Shop-Cart-Description-Section'>
                                            <p className='Shop-Cart-Product-Name'>{product.name}</p>
                                            <div className='Shop-Cart-Product-Quantity'>
                                                <button className='Shop-Cart-Product-Quantity-Remove' onClick={() => removeItemCart(product.id)}>-</button>
                                                <p className='Shop-Cart-Product-Quantity-Amount'>{product.quantity}</p>
                                                <button className='Shop-Cart-Product-Quantity-Add' onClick={() => addToCart(product)}>+</button>
                                            </div>
                                            <div className='Shop-Cart-Product-Quantity-ItemSummary'>
                                                <p className='Shop-Cart-Product-Quantity-ItemPrice'>${calculateItemTotal(product.quantity, product.price)}</p>
                                                <p className='Shop-Cart-Product-Quantity-ItemSave'>Save for later</p>
                                                <p className='Shop-Cart-Product-Quantity-ItemRemove' onClick={() => removeAllSpecificItem(product.id)}>Remove</p>
                                            </div>  
                                        </div>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </section> 
                <div className='Shop-Cart-Items-Total-Section'>
                    <div className='Shop-Cart-Items-Total-SectionStyle'>
                        <div className='Shop-Cart-Items-Total-Subtotal'>
                            <p className='Shop-Cart-Items-Total-Text'>SubTotal:</p>
                            <p className='Shop-Cart-Items-Total-CountItem'>{calculateAmountOfItem()} items</p>
                        </div>
                        <p className='Shop-Cart-Items-Total-Amount'>C$ ${calculateTotal()}</p>
                    </div>
                </div>
                <div className='Shop-Cart-Buttons'>
                    <button className='Shop-Cart-Checkout'>Checkout</button>
                </div>
            </div>
            } 
        </div>
    )
}