import React, { createContext, useState, useContext, useEffect, Children, useRef } from 'react';
import axios from 'axios';
import { json } from 'react-router-dom';


const CART_EXPIRATION_TIME = 24 * 60 * 60 * 1000;

export const ShopContext = createContext();

const productTest = [
    {id:1,
        imageProduct: '/images/face-cream.jpg', 
        name: 'Hyper Real Fresh Canvas Cream-To-Foam Cleanser' , 
        description: "DSAAAAAA adsaaaaaaaaaaaaa asdddddddddddddddddddddddddddddddddddddddddddd asddddddddddddddddddddddd",
        rating: 3.6,
        price: 20
    },
    {id:2, 
        imageProduct: '/images/face-cream.jpg', 
        name: 'Hyper Real Fresh Canvas Cream-To-Foam Cleanser',
        rating: 4.5,
        price: 34
    },
    {id:3, 
        imageProduct: '/images/face-cream.jpg',
        name: 'Hyper Real Fresh Canvas Cream-To-Foam Cleanser',
        rating: 2.6,
        price: 12
    },
    {id:4, 
        imageProduct: '/images/face-cream.jpg', 
        name: 'Hyper Real Fresh Canvas Cream-To-Foam Cleanser',
        rating: 5,
        price: 123.21
    }
];

export const ShopProvider = ({ children }) => {
    const [products, setProducts] = useState([]);
    const [cart, setCart] = useState([]);
    const [isCartVisible, setCartVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const shopCategories = [
        {
            id: 1,
            name: 'Moisturizers'
        }
    ]
    const cartRef = useRef(null);


    const fetchAllProducts = async () => {
        try {
            const response = await axios.post('/api/ShopItems');
            setProducts(response.data);
        } catch (err) {
            console.error("Error fetching all products:", err);
        }
    };

    const fetchProductsByCategory = async (category) => {
        try {
            const response = await axios.post('/api/ShopItemsByCategory', { category })
            setProducts(response.data)
        } catch (err) {
            console.error("Error fetching products by category", err);
        }
    };

    const handleCategoryChange = (category) => {
        if (category) {
            const categoryLower = category.toLowerCase()
            setSelectedCategory(categoryLower);
            fetchProductsByCategory(categoryLower);
        } else {
            fetchAllProducts();
        }
    };

    // Fetch Posts from backend or localstorage
    useEffect(() => {
        fetchAllProducts();
        
        const savedCart = localStorage.getItem('cart');
        const savedCartTime = localStorage.getItem('cartTimestamp');
        
        if (savedCart && savedCartTime) {
            const currentTime = Date.now();

            if (currentTime - parseInt(savedCartTime, 10) < CART_EXPIRATION_TIME) {
                setCart(JSON.parse(savedCart))
            } else {
                localStorage.removeItem('cart');
                localStorage.removeItem('cartTimestamp')
            }
        }
    }, []);

    // Save cart to localStorage when it changes
    useEffect(() => {
        if (cart.length > 0) {
            localStorage.setItem('cart', JSON.stringify(cart));
            localStorage.setItem('cartTimestamp', Date.now().toString());
        } else {
            localStorage.removeItem('cart');
            localStorage.removeItem('cartTimestamp');
        }
    }, [cart])

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (
                cartRef.current && 
                !cartRef.current.contains(e.target)
            ) {
                console.log("testing false");
                setCartVisible(false); // Close the cart
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [cartRef]);
    
    const calculateAmountOfItem = () => {
        return cart.length;
    };

    const calculateTotal = () => {
        return cart.reduce((total, item) => total + (item.price * item.quantity), 0).toFixed(2);
    };

    const calculateItemTotal = (quantity, price) => {
        return (quantity * price).toFixed(2);
    }

    
    const addToCart = (product) => {
        setCart(prevCart => {
          const existingProduct = prevCart.find(item => item.id === product.id);
          if (existingProduct) {
            return prevCart.map(item =>
              item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
            );
          }
          return [...prevCart, { ...product, quantity: 1 }];
        });
    };

    const removeItemCart = (id) => {
        setCart( prev => prev.map(item => 
            item.id === id
            ? {...item, quantity: item.quantity - 1}
            : item).filter(item => item.quantity > 0)
        );
    };

    const removeAllSpecificItem = (id) => {
        setCart(prev => prev.filter(item => item.id !== id));
    }

    const clearCart = () => {
        setCart([]);
    };

    
    const toggleVisible = () => {
        setCartVisible((prev) => !prev);
    };

    const filteredProducts = searchTerm
    ? products.filter(product => 
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.description?.toLowerCase().includes(searchTerm.toLowerCase())
    )
    : products;

    

    

    return (
    <ShopContext.Provider value={{ 
        products: filteredProducts, 
        cart, 
        addToCart, 
        removeItemCart, 
        clearCart, 
        isCartVisible, 
        cartRef, 
        toggleVisible, 
        calculateTotal, 
        calculateItemTotal,
        setSearchTerm,
        searchTerm,
        handleCategoryChange,
        selectedCategory,
        removeAllSpecificItem,
        calculateAmountOfItem
        }}>
        { children }
    </ShopContext.Provider>
    );

};

