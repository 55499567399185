import './starRating.scss';


export default function StarRating({ rating }) {
    const fullStarts = Math.floor(rating);
    const halfStar = rating - fullStarts;
    const emptyStars = 5 - fullStarts - (halfStar > 0 ? 1 : 0);
    const percentageFilled = halfStar * 100;

    return(
        <div className="Star-Rating">
            {Array(fullStarts).fill(null).map((_, index) => (
                <i key={index} className="Star-Rating-Icons fa-solid fa-star"></i>
            ))}
            
            {halfStar > 0 && halfStar < 1 &&
                <div className='Half-Star-Container'> 
                    <div className='Half-Star-Filled' 
                    style={{
                        background: `linear-gradient(to right, #ffd700 ${percentageFilled}%, rgb(0,0,0,0) ${percentageFilled}%)`,
                    }}> 
                    </div>
                    
                    <i className="Star-Rating-Icons fa-regular fa-star"></i>
                </div>
                //<i className="Star-Rating-Icons fa-solid fa-star-half-stroke"></i>
            }

            {Array(emptyStars).fill(null).map((_, index) => (
                <i key={index} className="Star-Rating-Icons fa-regular fa-star"></i>
            ))}
        </div>
    );
};
