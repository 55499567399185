import "./setting.scss";
import BlogFooter from "../../components/blog-footer/BlogFooter"
import React, { useEffect, useState } from 'react';
import InvalidError from './invalidSettingsMessage'
import axios from "axios";


export default function Settings() {
  const [inputError, setInputError] = useState({
    emailError: false, 
    userNameError: false, 
    passwordError: false
  });

  const [successMessage, setSuccessMessage] = useState('');

  // Handles Password Details
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  //Handles Profile Image Data
  const [profileImage, setProfileImage] = useState(null);
  const [profileImageFile, setProfileImageFile] = useState(null);
  //Handles Username
  const [userName, setUserName] = useState('');
  //Handles email
  const [newEmail, setNewEmail] = useState('');

  const handleProfileImageChange = (e) => {
    const newImage = e.target.files[0];
    setProfileImageFile(newImage);
    
    const reader = new FileReader();
    reader.onload = () => {
      setProfileImage(reader.result);
    };

    if (newImage) {
      reader.readAsDataURL(newImage);
    }
  };

  const getUserImage = async () => {
    try {
        const response = await axios.post('/api/getUserImage');
        setProfileImage(response.data.userProfileImage);
    } catch (error) {
        console.error('Error fetching user image:', error);
    }
  }

  const handleProfileImageSubmit = async (e) => {
    try {
      const formData = new FormData();
      formData.append('profileImage', profileImageFile);

      const response = await axios.post('/api/updateProfileImage', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    });

    if (response.success) {
      setSuccessMessage("Update Image Successfully");
    }


    } catch (err) {
      console.log("Error Submitting Image", err)
    }
  };

  // Password Submit Handling
  const handlePasswordSubmit = async (e) => {
    try {
      const response = await axios.post('/api/updatePassword', { password: newPassword});

      if (response.data.success) {
        setInputError(prevState => ({...prevState, passwordError: false }));
        console.log("Updated Succefully");
      } else {
        console.log("Issue with the password");
        setInputError(prevState => ({...prevState, passwordError: true}));
      }
    } catch {
      console.log("Error Updating Password");
    }
    setNewPassword('');
    setConfirmPassword('');
  };

  // Email Submit Handling
  const handleEmailSubmit = async (e) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailPattern.test(newEmail)) {
      setInputError(prevState => ({...prevState, emailError: true }));
      return;
    }
    try {
      const response = await axios.post('/api/updateEmail', { email: newEmail });
      if (response.data.success) {
        setInputError(prevState => ({...prevState, emailError: false }));
        console.log('Email updated successfully');
      } else {
        setInputError(prevState => ({...prevState, emailError: true }));
      }
    } catch (error) {
      setInputError(prevState => ({...prevState, emailError: true }));
      console.log('Error submitting changes', error);
    }

    setNewEmail('');
  };

  const handleUserNameSubmit = async (e) => {
    try {
      const response = await axios.post("/api/updateUserName", { newUserName: userName});
      
      if (response.data.success) {
        setInputError(prevState => ({...prevState, userNameError: false }));
        console.log("Updated the username succefully");
      } else {
        setInputError(prevState => ({...prevState, userNameError: true }));
        console.log("Username update failed");
      }
    } catch (err) {
      setInputError(prevState => ({...prevState, userNameError: true }));
      console.log("Error updating the username", err);
    }
    setUserName('');
  };

  const handleGlobalSubmit = async (e) => {
    try {
      const formData = new FormData();
      formData.append('profileImage', profileImage);
      formData.append('newEmail', newEmail);
      formData.append('newPassword', newPassword);
      formData.append('newUseName', userName);

      const response = await axios.post('/api/globalUpdate', formData, {
        'Content-Type': 'multipart/form-data',
      });

      if (response.data.success) {
        console.log("Updated the username succefully");
      } else {
        console.log("Failed")
      }


    } catch (err) {
      console.log("Fail", err);
      
    }
  };

  useEffect(() => {
    getUserImage();
  }, []);


  return (
    <div className="settings-page-container">
      <div className="settings">
        <div className="settingsWrapper">
          <div className="settingsTitle">
            <span className="settingsTitleUpdate">Update Your Account</span>
          </div>
          <div className="settings-container">
            <div className="settingsForm">
              <div className="profile-image-change">
                <div className="settingsPP">
                  <img
                    src={profileImage ? profileImage : "https://images.pexels.com/photos/6685428/pexels-photo-6685428.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"}
                    alt=""
                  />
                  <label htmlFor="fileInput">
                    <i className="settingsPPIcon far fa-user-circle"></i>{" "}
                  </label>
                  <input
                    id="fileInput"
                    type="file"
                    style={{ display: "none" }}
                    className="settingsPPInput"
                    onChange={handleProfileImageChange}
                  />
                </div>
              </div>
              <div className="center-submit-button-settings">
                    <button className="settingsSubmitButton" type="submit" onClick={handleProfileImageSubmit}>
                      Update Profile Image
                    </button>
              </div>
              <div className="change-userinfo">
                <div className="change-userinf-email">
                  <label>Email</label>
                  <input 
                    type="email" 
                    placeholder="Maria@gmail.com" 
                    name="email" 
                    autoComplete="off"
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                  />
                  {inputError.emailError && 
                  <InvalidError fieldName="Email"/>
                  }
                  <div className="center-submit-button-settings">
                    <button className="settingsSubmitButton" type="submit" onClick={handleEmailSubmit}>
                      Update Email
                    </button>
                  </div>
                </div>
                <div className="change-userinf-password">
                  <label>Password</label>
                  <input type="password" placeholder="Password" name="password" autoComplete="new-password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                  <label>Confirm Password</label>
                  <input type="password" placeholder="Confirm Password" name="confirmPassword" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                  {inputError.passwordError && 
                  <InvalidError fieldName="Password"/>
                  }
                  <div className="center-submit-button-settings">
                    <button className="settingsSubmitButton" type="submit" onClick={handlePasswordSubmit}>
                      Update Password
                    </button>
                  </div>
                </div>
                <div className="change-userinf-username">
                  <label>Username</label>
                  <input type="text" 
                  placeholder="Maria" 
                  name="name" 
                  value={ userName }
                  onChange={(e) => setUserName(e.target.value)}/>
                  {inputError.userNameError && 
                  <InvalidError fieldName="Username"/>
                  }
                  <div className="center-submit-button-settings">
                    <button className="settingsSubmitButton" type="submit" onClick={handleUserNameSubmit}>
                      Update Username
                    </button>
                  </div>
                </div>
                <span className="settingsTitleDelete">Delete Account</span>
              </div>
            </div>
            <div>
              <button className="settingsSubmitButton" type="submit" onClick={handleGlobalSubmit}>
                Submit All Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <BlogFooter/>
    </div>
  );
}
