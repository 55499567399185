import RoutesBlog from './Routing/RoutesBlog';
import TopBar from "../src/components/navigation-components-blog/TopBar";
import { AuthProvider, useAuth } from './context/AuthContext';
import { ShopProvider } from './context/ShopContext';
import './App.css';



function App() {
  return (
    <ShopProvider>
      <AuthProvider>
        <TopBar/>
        <RoutesBlog />
      </AuthProvider>
    </ShopProvider>
  );
}

export default App;
