import { Routes, Route, Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import BlogHome from '../pages/home/BlogHome';
import ArticlesComponentMain from '../pages/single/blogArticles';
import Write from '../pages/write/blogWrite';
import Settings from '../pages/settings/Setting';
import SignUp from '../pages/signup/signup';
import Login from '../pages/login/login';
import DynamicPosts from '../pages/dynamicPosts/DynamicPosts';
import BodyWashComponentMain from '../pages/single/blogBodyWash';
import FaceCreamComponentMain from '../pages/single/blogFaceCream';
import ScrubComponentMain from '../pages/single/blogScrubs';
import EditPost from '../pages/editposts/editpost';
import Shop from '../pages/shop/shopPage';

import { useAuth } from '../context/AuthContext';

export default function RoutesBlog() {
    const { isLoggedIn } = useAuth();

    return(
        <Routes>
            <Route path="/" element={<BlogHome />} />
            { !isLoggedIn && <Route path="/login" element={<Login />} />}
            <Route path="/Articles" element={<ArticlesComponentMain />} />
            <Route path="/BodyWash" element={<BodyWashComponentMain />} />
            <Route path="/FaceCream" element={<FaceCreamComponentMain />} />
            <Route path="/Scrubs" element={<ScrubComponentMain />} />
            { isLoggedIn && (
            <>
            <Route path="/Write" element={<Write />} />
            <Route path="/Settings" element={<Settings />} />
            <Route path="/Signup" element={<SignUp />} /> 
            <Route path="/EditPost" element={<EditPost />} />
            <Route path="/Shop" element={<Shop />} />
            </>
            )} 
            <Route path="/DynamicPosts" element={<DynamicPosts />} />
        </Routes>
    )
}