import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import logOut from '../../utilityFunction/logout';
import "./dropmenulogin.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import React from 'react';

function showDropDownLogin() {
    document.getElementById('dropDownMenu').classList.toggle('show');
}

window.onclick = function(event) {
    const dropDown = document.getElementById('dropDownMenu');

    if (!event.target.closest('.dropMenu') && dropDown.classList.contains('show')) {
        dropDown.classList.remove('show');
    }
};

function DropMenuLogin( {dropMenuOuter, dropMenuText, dropMenuIcon, iconBorder}) {
    const { isLoggedIn } = useAuth();
    const [userImage, setUserImage] = useState(null);
    const [dropMenuStyle, setDropMenuStyle] = useState('dropDownMenuStyle');
    const [dropMenuIMGSize, setDropMenuIMGSize] = useState('top-bar-img-size-50');
    const nagvigate = useNavigate();

    const getUserImage = async () => {
        try {
            const response = await axios.post('/api/getUserImage');
            setUserImage(response.data.userProfileImage);
        } catch (error) {
            console.error('Error fetching user image:', error);
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 50) {
                setDropMenuIMGSize('top-bar-img-size-40');
            } else {
                setDropMenuIMGSize('top-bar-img-size-50');
            }
        })
    }, [])


    useEffect(() => {
        if (isLoggedIn) {
            getUserImage();
            setDropMenuStyle('dropDownMenuStyleLoggedIN');
        }
    }, [isLoggedIn]);

    const handleLogout = () => {
        logOut();
        nagvigate('/');
    }

    return (
      <>
        <div className="dropMenu">
        {isLoggedIn ? 
            (<div className="SignInContainer-LoggedIn" onClick={showDropDownLogin}>
                {userImage ? 
                (<div className={`${dropMenuIMGSize} top-bar-right-login-img-present-outside`}>
                   <img className="top-bar-right-login-img-present" src={userImage} alt="User" />
                </div>) : 
                (
                    <div className="top-bar-right-login-icon-outside">
                        <i className="top-bar-right-login-icon fa-solid fa-user"></i>
                    </div>
                )}
            </div>) : 
            (<div className={dropMenuOuter} onClick={showDropDownLogin}>
                <div className={iconBorder}>
                    <i className={`${dropMenuIcon} fa-solid fa-user`}></i>
                </div>
                <span className={dropMenuText}>Sign In</span>
            </div>
        )}
            <nav>
                <ul id="dropDownMenu" className={dropMenuStyle}>
                    { !isLoggedIn && <li className="dropList" onClick={showDropDownLogin}>
                        <Link className="dropLink" to='/Login'>Login</Link></li> 
                    }
                    { isLoggedIn && (<>
                    <li className="dropList" onClick={showDropDownLogin}><Link className="dropLink" to='/'>Home</Link></li>
                    <li className="dropList" onClick={showDropDownLogin}><Link className="dropLink" to='/Write'>Write</Link></li>
                    <li className="dropList" onClick={showDropDownLogin}><Link className="dropLink" to='/Editpost'>Edit</Link></li>
                    <li className="dropList" onClick={showDropDownLogin}><Link className="dropLink" to='/AllPosts'>Blog</Link></li>
                    <li className="dropList" onClick={showDropDownLogin}><Link className="dropLink" to='/Settings'>Settings</Link></li>
                    <li className="dropList" onClick={showDropDownLogin}><Link className="dropLink" to='/signup'>Sign Up</Link></li>
                    <li className="dropLink dropList" onClick={handleLogout}>Sign Out</li>
                    </>) 
                    }
                    <li className="dropList" onClick={showDropDownLogin}><Link className="dropLink" to='/Shop'>Shop</Link></li> 
                </ul>
            </nav>
        </div>
      </>
    );
}

export default DropMenuLogin;

  
