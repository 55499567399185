import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./blogwrite.scss";
import Breadcrumbs from "../../components/navigation-components-blog/BreadcrumbNav";
import BlogFooter from "../../components/blog-footer/BlogFooter";
import ListMenu from "../../components/blog-write-components/listMenu";
import ListMenuTechnologies from "../../components/blog-write-components/listMenuTechnologies";
import HeaderImageWrite from "../../components/blog-write-components/headerImageWrite"
import { getCurrentDateTime } from '../../utilityFunction/formatPostDate';


export default function BlogWrite() {
  // Handles DropMenu File Selections
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const toggleRef = useRef(null);

  // Handles the specific file to which to save the post
  const [fileSelected, setFileSelection] = useState('Articles');

  //Handles the Text
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  
  // Handles the Images
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageFile, setImageFile] = useState([]);

  const [fileOpenField, setFileOpenField] = useState(false);

  // Handles the Bottom Inputs
  const [ingredientsA, setIngredientsA] = useState([{ name: '', percentage: '', amount: '' }]);
  const [ingredientsB, setIngredientsB] = useState([{ name: '', percentage: '', amount: '' }]);
  const [ingredientsC, setIngredientsC] = useState([{ name: '', percentage: '', amount: '' }]);
  const [technologies, setTechnologies] = useState([{ number: '', description: '' }]);

  // Handles Adding costs/weight/time
  const [generalInfo, setGeneralInfo] = useState([{ weight: '', time: ''}]);


  // Handle Header Image
  const [imageHeader, setImageHeader] = useState(null);
  const [imageFileHeader, setImageFileHeader] = useState(null);


  // Handles Image Resizing
  const [imageStates, setImageStates] = useState([]);
  const [imageDragIndex, setimageDragIndex] = useState(null);

  const handleSliderChange = (e) => {
    const newImageStates = [...imageStates];
    newImageStates[currentImageIndex].scale = parseFloat(e.target.value);
    setImageStates(newImageStates);
  };


  useEffect(() => {
    const handleMouseMove = (e) => {
      if (imageDragIndex == null) return;

      const deltaX = e.clientX - imageStates[imageDragIndex].prevPosition.x;
      const deltaY = e.clientY - imageStates[imageDragIndex].prevPosition.y;

      const newImageStates = [...imageStates];
      newImageStates[imageDragIndex].position = {
        x: newImageStates[imageDragIndex].position.x + deltaX,
        y: newImageStates[imageDragIndex].position.y + deltaY,
      };
      newImageStates[imageDragIndex].prevPosition = { x: e.clientX, y: e.clientY };

      setImageStates(newImageStates);
    };

    const handleTouchMove = (e) => {
      if (imageDragIndex == null || e.touches.length !== 1) return;

      const touch = e.touches[0];
      const deltaX = touch.clientX - imageStates[imageDragIndex].prevPosition.x;
      const deltaY = touch.clientY - imageStates[imageDragIndex].prevPosition.y;

      const newImageStates = [...imageStates];
      newImageStates[imageDragIndex].position = {
          x: newImageStates[imageDragIndex].position.x + deltaX,
          y: newImageStates[imageDragIndex].position.y + deltaY,
      };
      newImageStates[imageDragIndex].prevPosition = { x: touch.clientX, y: touch.clientY };

      setImageStates(newImageStates);

      e.preventDefault(); // Prevent default behavior
    };

    const handleMouseUp = () => {
      setimageDragIndex(null);
    };

    const handleTouchEnd = (e) => {
      setimageDragIndex(null);
      e.preventDefault(); // Prevent default behavior
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('touchmove', handleTouchMove, { passive: false });
    window.addEventListener('touchend', handleTouchEnd, { passive: false });

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleTouchEnd);
    };


  }, [imageDragIndex, imageStates]);

  const handleMouseDown = (e) => {
    const newImageStates = [...imageStates];
    newImageStates[currentImageIndex].prevPosition = { x: e.clientX, y: e.clientY };
    setImageStates(newImageStates);
    setimageDragIndex(currentImageIndex);
  };

  // Showcase image First one added
  const handleImageChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const newImageState = selectedFiles.map(() => ({
      scale: 1,
      position: { x: 0, y: 0},
      prevPosition: { x: 0, y: 0}
    })
  );


    setImageFile([...imageFile, ...selectedFiles]);
    setImageStates([...imageStates, ...newImageState]);
    setCurrentImageIndex(imageFile.length);
  };

  const handleAddingExtraImages = (event) => {
    if (imageFile.length < 10) {
      const selectedFiles = Array.from(event.target.files);
      const newImageStates = selectedFiles.map(() => ({
        scale: 1,
        position: { x: 0, y: 0},
        prevPosition: { x: 0, y: 0}
      }));

      setImageFile([...imageFile, ...selectedFiles]);
      setImageStates([...imageStates, ...newImageStates]);
      setCurrentImageIndex(imageFile.length);
    } else {
      // You can display a message or handle the limit in any way you prefer
      console.log("Image limit reached. You can't add more images.");
    }
  }

  const handleToggleFileInput = () => {
    if (fileOpenField === false) {
        setCurrentImageIndex(0);
        setImageFile([]);
        setImageHeader(null);
        setImageFileHeader(null);
        setImageStates([]);
        setimageDragIndex(null);
    }
    setFileOpenField((prev) => !prev);
  }

  const handleDeleteCurrentImage = () => {
    const updatedImages = [...imageFile];
    updatedImages.splice(currentImageIndex, 1);
    setImageFile(updatedImages);

    const updatedStates = [...imageStates];
    updatedStates.splice(currentImageIndex, 1);
    setImageStates(updatedStates);
    
    if (updatedImages.length > 0) {
        setCurrentImageIndex(0);
    }

    URL.revokeObjectURL(imageFile[currentImageIndex]); 
  }

  

  const generalInputChange = (index, field, value) => {
    const updatedGeneralInfo = [...generalInfo];
    updatedGeneralInfo[index][field] = value;
    setGeneralInfo(updatedGeneralInfo);
  };

  // For the header component 
  const handleImageChangeHead = (event) => {
        const selectFile = event.target.files[0];
        setImageFileHeader(selectFile);

        const reader = new FileReader();
        reader.onload = () => {
            setImageHeader(reader.result);
        };
        
        if (selectFile) {
            reader.readAsDataURL(selectFile);
        }
    };

  const handleDeleteHeader = () => {
        if (imageHeader) {
            setImageHeader(null);
            setImageFileHeader(null);
        }
  };

  

  const handleFileSelection = (value) => {
    setFileSelection(value);
    //toggleDropedown();
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        toggleRef.current &&
        !toggleRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropedown = (event) => {
     setIsOpen((prevState) => !prevState);
   };




  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append('fileSelected', fileSelected.toLowerCase());
      formData.append('title', title);
      formData.append('description', JSON.stringify(description));
      formData.append('datePosted', getCurrentDateTime());
      formData.append('imageHeader', imageFileHeader);

      // Append other images as additionalImages if they exist
      imageFile.forEach((file, index) => {
          formData.append(`additionalImages[${index}]`, file);
          formData.append(`transformations[${index}]`,  JSON.stringify(imageStates[index]));
      });
      

      ingredientsA.forEach(({ name, percentage, amount }, index) => {
        formData.append(`ingredientsA[${index}][name]`, name);
        formData.append(`ingredientsA[${index}][percentage]`, percentage);
        formData.append(`ingredientsA[${index}][amount]`, amount);
      });
  
      ingredientsB.forEach(({ name, percentage, amount }, index) => {
        formData.append(`ingredientsB[${index}][name]`, name);
        formData.append(`ingredientsB[${index}][percentage]`, percentage);
        formData.append(`ingredientsB[${index}][amount]`, amount);
      });
  
      ingredientsC.forEach(({ name, percentage, amount }, index) => {
        formData.append(`ingredientsC[${index}][name]`, name);
        formData.append(`ingredientsC[${index}][percentage]`, percentage);
        formData.append(`ingredientsC[${index}][amount]`, amount);
      });

      technologies.forEach(({ number, description }, index) => {
        formData.append(`technologies[${index}][number]`, number);
        formData.append(`technologies[${index}][description]`, description);
      });

      generalInfo.forEach(({ weight, time }, index) => {
        formData.append(`generalInfo[${index}][weight]`, weight);
        formData.append(`generalInfo[${index}][time]`, time);
      });

      const response = await axios.post('/api/posts', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      console.log('New post created:', response.data);

      // Reset after submit
      //setFileSelection('posts');
      setTitle('');
      setDescription('');
      // Image Things
      setCurrentImageIndex(0);
      setImageFile([]);
      imageFile.forEach(file => URL.revokeObjectURL(file));
      // Header Image
      setImageHeader(null);
      setImageFileHeader(null);

      // Ingredients
      setIngredientsA([{ name: '', percentage: '', amount: ''}]);
      setIngredientsB([{ name: '', percentage: '', amount: ''}]);
      setIngredientsC([{ name: '', percentage: '', amount: ''}]);
      setTechnologies([{number: '', description: ''}]);
      setGeneralInfo([{ weight: '', time: ''}]);
    } catch (error) {
      console.error('Error creating new post:', error);
    }
  };

 

  return (
    <>
      <div className="main-container">
        <Breadcrumbs />
        <div className="blog-write">
          <div className="blog-writeContainer">
            <div className="file-selection-write-page">
              <label htmlFor="file-select">Select Blog Post Destination:</label>
              <div className="dropdown-toggle-write-page" onClick={toggleDropedown} ref={toggleRef}>
                <p>{fileSelected}</p>
                <i className="chevron-write-page-posts fa-solid fa-chevron-down"></i>
              </div>
              {isOpen && (
                <ul id="file-select" className="dropdown-list-write-page" ref={dropdownRef}>
                  <li className="write-post-option" onClick={() => handleFileSelection("Articles")}>Articles</li>
                  <li className="write-post-option" onClick={() => handleFileSelection("Scrubs")}>Scrubs</li>
                  <li className="write-post-option" onClick={() => handleFileSelection("Face")}>Face</li>
                  <li className="write-post-option" onClick={() => handleFileSelection("Body")}>Body</li>
                </ul>
              )}
            </div>
            <div className="blog-write-ImageInputOutside">
              {!fileOpenField && (
              <div className="blog-open-image-icon">
                <label htmlFor="blog-fileInput" onClick={handleToggleFileInput}>
                  <i className="blog-writeIcon fas fa-plus"/>
                </label>
              </div>
              )}
              {fileOpenField && (
                <div className="fileInputBackground">
                  <HeaderImageWrite
                  handleImageChangeHead = {handleImageChangeHead}
                  handleDeleteHeader = {handleDeleteHeader}
                  headerData = {imageHeader}
                  toggleImageInput = {handleToggleFileInput}
                  />
                  {imageFile.length === 0 && (
                    <label htmlFor="blog-fileInput" className="fileInputBackground-image-closed">
                        <div className="fileInput-files-symbol">
                          <label htmlFor="blog-fileInput">
                            <i className="blog-OpenFileIcon fa-solid fa-photo-film"/>
                          </label>
                        </div>
                        <div className="fileInputBackground-top-text">
                          <span className="fileInputBackground-top-text">Add Photos/Videos</span>
                        </div>
                        <div className="fileInputBackground-bot-text">
                          <span>or drag and drop</span>
                        </div>
                        <input 
                        id="blog-fileInput" 
                        type="file" 
                        style={{ display: "none" }} 
                        onChange={handleImageChange} 
                        multiple/>
                    </label>
                  )}
                  {imageFile.length > 0 && (
                    <div className="fileInputBackground-image-open">
                        <div className="blog-writeHeaderImage">
                          <div className="blog-writeHeaderImage-Border">
                            <img
                            className="blog-image-styling" 
                            alt=""
                            style={{
                              transform: `scale(${imageStates[currentImageIndex]?.scale || 1})
                              translate(${imageStates[currentImageIndex]?.position.x || 0}px, 
                                ${imageStates[currentImageIndex]?.position.y || 0}px)
                              `
                            }}
                            draggable={false}
                            onMouseDown={handleMouseDown}
                            onTouchStart={handleMouseDown}
                            src={URL.createObjectURL(imageFile[currentImageIndex])}
                            >
                            </img>
                          </div>
                        </div>
                        <div className="fileInput-AddMore-Symbol">
                          <label>
                            <input
                              type="file"
                              style={{ display: "none" }}
                              onChange={handleAddingExtraImages}
                              multiple
                            />
                            <i className="blog-addMoreImagesIcon fas fa-plus"></i>
                          </label>  
                        </div>
                        <div className="fileInput-Remove-Symbol">
                          <label onClick={handleDeleteCurrentImage}>
                            <i className="blog-addRemoveImagesIcon fa-solid fa-trash"></i>
                          </label>  
                        </div>
                        <div className="blog-image-scale-location">
                          <input
                            type="range"
                            min="0.1"
                            max="3"
                            step="0.1"
                            value={imageStates[currentImageIndex]?.scale || 1}
                            onChange={handleSliderChange}
                          />
                        </div>
                      {imageFile.length > 1 && (
                        <div className="image-navigation-buttons">
                          <button
                            onClick={() =>
                              setCurrentImageIndex((prevIndex) =>
                                (prevIndex - 1 + imageFile.length) % imageFile.length
                              )
                            }
                          >
                            Previous
                          </button>
                          <button
                            onClick={() =>
                              setCurrentImageIndex((prevIndex) =>
                                (prevIndex + 1) % imageFile.length
                              )
                            }
                          >
                            Next
                          </button>
                        </div>
                      )}
                    </div>   
                  )}
                </div>
              )}
            </div>
            <div className="blog-writeFormGroup">
              <input
                className="blog-writeInput"
                placeholder="Title"
                type="text"
                autoFocus={true}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <textarea
                className="blog-writeInput blog-writeText"
                placeholder="Tell your story..."
                type="text"
                autoFocus={true}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <div className="blog-writeInput-Ingredients">
                <div className="blog-write-general-outer">
                  <h3>General Information</h3> 
                  {generalInfo.map((info, index) => (
                      <div className="write-page-general-information" key={index}>
                        <input
                          className="input-fields-write-page"
                          placeholder="Weight"
                          type="text"
                          value={info.weight}
                          onChange={(e) => generalInputChange(index, 'weight', e.target.value)}
                        />
                        <input
                          className="input-fields-write-page"
                          placeholder="Time"
                          type="text"
                          value={info.time}
                          onChange={(e) => generalInputChange(index, 'time', e.target.value)}
                        />
                      </div>
                    ))}
                </div>
                <div className="ingredients-container-outer">
                  <h3>Input Ingredients A</h3>
                  <ListMenu data={ingredientsA} setData={setIngredientsA} fieldNames={{ name: 'Name', percentage: 'Percentage', amount: 'Amount' }} />
                </div>
                <div className="ingredients-container-outer">
                  <h3>Input Ingredients B</h3>
                  <ListMenu data={ingredientsB} setData={setIngredientsB} fieldNames={{ name: 'Name', percentage: 'Percentage', amount: 'Amount' }} />
                </div>
                <div className="ingredients-container-outer">
                  <h3>Input Ingredients C</h3>
                  <ListMenu data={ingredientsC} setData={setIngredientsC} fieldNames={{ name: 'Name', percentage: 'Percentage', amount: 'Amount' }} />
                </div>
                <div className="write-page-input-TechCont">
                  <h3>Input Technologies</h3>
                  <ListMenuTechnologies data={technologies} setData={setTechnologies} fieldNames={{ number: 'Number', description: 'Description' }} />
                </div>
              </div>
            </div>
            <div className="blog-writeSubmitOuter">
              <button className="blog-writeSubmit" type="submit" onClick={handleSubmit}>
                Publish
              </button>
            </div>
          </div>
        </div>
      </div>
      <BlogFooter/>
    </>
  );
}
